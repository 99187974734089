import React from 'react';
import _get from 'lodash/get';
import { Link, graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Content from '../components/Content';
import Layout from '../components/Layout';

export const SinglePostTemplate = ({ title, date, body, categories = [] }) => (
  <main style={{ marginTop: '105px' }}>
    <Container fluid>
      <Row>
        <Col xs={12}>
          <article
            style={{ maxWidth: '728px', margin: '0 auto' }}
            itemScope
            itemType="http://schema.org/BlogPosting"
          >
            <Link className="mb-5" to="/blog/">
              &laquo; Back
            </Link>

            {title && (
              <h1 itemProp="title" className="mb-0">
                {title}
              </h1>
            )}

            <p>
              {date && (
                <time
                  className="text-muted"
                  itemProp="dateCreated pubdate datePublished"
                  date={date}
                >
                  {date}
                </time>
              )}
              {categories && (
                <>
                  <span className="mx-2 text-muted">|</span>
                  {categories.map((cat, index) => (
                    <span className="text-muted" key={`${cat.category}-${index}`}>
                      {cat.category}
                      {/* Add a comma on all but last category */}
                      {index !== categories.length - 1 ? ',' : ''}
                    </span>
                  ))}
                </>
              )}
            </p>

            <Content source={body} />
          </article>
        </Col>
      </Row>
    </Container>
  </main>
);

// Export Default SinglePost for front-end
const SinglePost = ({ data: { post, allPosts } }) => {
  const thisEdge = allPosts.edges.find((edge) => edge.node.id === post.id);
  return (
    <Layout meta={post.frontmatter.meta || false} title={post.frontmatter.title || false}>
      <SinglePostTemplate
        {...post}
        {...post.frontmatter}
        body={post.html}
        nextPostURL={_get(thisEdge, 'next.fields.slug')}
        prevPostURL={_get(thisEdge, 'previous.fields.slug')}
      />
    </Layout>
  );
};

export default SinglePost;

export const pageQuery = graphql`
  ## Query for SinglePost data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SinglePost($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      frontmatter {
        title
        template
        subtitle
        date(formatString: "MMMM Do, YYYY")
        categories {
          category
        }
      }
    }

    allPosts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "posts" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
        }
        next {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
        previous {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
